// =====================|| THEME CONFIG  ||===================== //
const config = {
    defaultPath: '/dashboard/default',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};
export default config;
// ================|| END OF THEME CONFIG  ||=================== //

///////////////////////////////////////////////////////////////////
///////               SGP CONFIGURATION FILE                ///////
///////                                                     ///////
///////  Format of the config file:                         ///////
///////                                                     ///////
///////    /////<SECTION HEADER> (<SECTION TITLE>)          ///////
///////    ///<DESCRIPTION> (<TYPE>)                        ///////
///////    <CONFIG_VARIABLE> = <CONFIG_VALUE>               ///////
///////    ///<DESCRIPTION> (<TYPE>)                        ///////
///////    <CONFIG_VARIABLE> = <CONFIG_VALUE>               ///////
///////    ///<DESCRIPTION> (<TYPE>)                        ///////
///////    <CONFIG_VARIABLE> = <CONFIG_VALUE>               ///////
///////    ...                                              ///////
///////                                                     ///////
///////    Legend:                                          ///////
///////      - <SECTION HEADER> will determine in which     ///////
///////        section within the UI the config variable    ///////
///////        will appear under. Note: <SECTION HEADER>    ///////
///////        should always start with '/////'             ///////
///////      - <SECTION TITLE> is optional value that will  ///////
///////        replce the text used for the section. If     ///////
///////        this value is not provided then it will use  ///////
///////        the <SECTION HEADER>'s value. Note:          ///////
///////        <SECTION TITLE> should always be within ()   ///////
///////        and the same line as the <SECTION HEADER>    ///////
///////      - <DESCRIPTION> is the description of the      ///////
///////        configuration variable. Note: <DESCRIPTION>  ///////
///////        should always start with '///' and should    ///////
///////        be a line above the corresponding            ///////
///////        <CONFIG_VARIABLE>                            ///////
///////      - <TYPE> is the type of the configuration      ///////
///////        variable. Note: <TYPE> should always be      ///////
///////        within () and the same line as the           ///////
///////        <DESCRIPTION>                                ///////
///////        Example: (int) or (bool) or (str)            ///////
///////      - <CONFIG_VARIABLE> is the name of the         ///////
///////        configuration variable                       ///////
///////        variable.                                    ///////
///////      - <CONFIG_VALUE> is the value associated with  ///////
///////        the configuration variable                   ///////
///////                                                     ///////
///////////////////////////////////////////////////////////////////

/////GENERAL SETTINGS
///This will enable React in Debug mode. (bool)
export const debug = false;
///Timeout in milliseconds associated with alerts (int)
export const AlertTimeoutLength = 0
///List of all compressed files that can be decompressed upon upload via Console (str)
export const DecompressFileExtensions = ['.zip', '.tar.gz', '.tar', '.tar.xz']
///Enables CAPTCHA at the time of sign up (bool)
export const CAPTCHA_ENABLED = false;
///URL used to fecth the Public IP address of the user that is logged in (str)
export const FETCH_USER_IP_ROUTE = "https://geolocation-db.com/json/"
//export const FETCH_USER_IP_ROUTE = "https://api.ipify.org/?format=json"
///Comapares IP address where user is initiating login for auto login (bool)
export const SIGN_IN_COMPARE_IP = false
///Message that appears when token is expired (str)
export const TOKEN_EXPIRE_MESSAGE = 'Token has expired'
///This will determine which route user is directed to upon successfull login
export const REDIRECT_LOGIN = 'dashboard'
///This will determine which route user is directed to upon an error
export const REDIRECT_ERR_PAGE_URL = 'dashboard'

/////SIDEBAR SETTINGS
///Determines whether Sidebar text is show or not (bool)
export const drawerText = false;
///Determines whether Sidebar has a divider or not (bool)
export const drawerTextDivider = true;
///Dertermines the width of the Sidebar in pixels (int)
export const drawerWidth = 80;

/////REACT SERVER SETTINGS
///This is the protocol for the React service will be initiated from. (str)
export const GLOBAL_PROTO = 'https';
///This is the IP address for the React service will be initiated from. (str)
export const GLOBAL_HOST = 'app.securegen.com';
//export const GLOBAL_HOST = '10.0.1.116';
///This is the port for the React service will be initiated from. Note, this can be 'false' as well. (str)
export const GLOBAL_PORT = false;
//export const GLOBAL_PORT = 5000;
///This is the URL for the React service will be initiated from. (str)
// ==================|| GLOBAL_URL CONFIG  ||=================== //
if(GLOBAL_PORT){
    var flaskURL = GLOBAL_PROTO+"://"+GLOBAL_HOST+":"+GLOBAL_PORT;
} else {
    var flaskURL = GLOBAL_PROTO+"://"+GLOBAL_HOST;
}
export const GLOBAL_URL = flaskURL;
// ============================================================= //

/////TTYD SETTINGS
///This is the protocol for the TTYD for the Terminal in Console. (str)
export const TERMINAL_PROTO = 'https';
///This is the IP address for the TTYD for the Terminal in Console. (str)
export const TERMINAL_HOST = '10.0.1.116';

/////SONIC API SETTINGS
///This is the protocol used to communicate with Sonic (str)
export const SONIC_PROTO = 'https';
///This is the hostname used to communicate with Sonic (str)
export const SONIC_HOST = 'sonic.securegen.com';
//export const SONIC_HOST = '54.165.194.109';
///This is the port used to communicate with Sonic. Note, can be 'False' if no port is specified. (str)
export const SONIC_PORT = false;
///This is the URL used to communicate with Sonic. (str)
// ===================|| SONIC_URL CONFIG  ||=================== //
if(SONIC_PORT){
    var sonicURL = SONIC_PROTO+"://"+SONIC_HOST+":"+SONIC_PORT;
} else {
    var sonicURL = SONIC_PROTO+"://"+SONIC_HOST;
}
export const SONIC_URL = sonicURL;
// ============================================================= //

/////SONIC ML API SETTINGS
///This is the protocol used to communicate with Sonic (str)
export const SONIC_ML_PROTO = 'http';
///This is the hostname used to communicate with Sonic (str)
export const SONIC_ML_HOST = '54.167.171.115';
///This is the port used to communicate with Sonic. Note, can be 'False' if no port is specified. (str)
export const SONIC_ML_PORT = false;
///This is the URL used to communicate with Sonic. (str)
// =================|| SONIC_ML_URL CONFIG  ||================== //
if(SONIC_ML_PORT){
    var sonicMLURL = SONIC_ML_PROTO+"://"+SONIC_ML_HOST+":"+SONIC_ML_PORT;
} else {
    var sonicMLURL = SONIC_ML_PROTO+"://"+SONIC_ML_HOST;
}
export const SONIC_ML_URL = sonicMLURL;
// ============================================================= //

/////SONIC AUDIT SETTINGS
///This is admin's username for Sonic (str)
export const SONIC_AUDIT_LOG_USERNAME = 'admin'
///This is admin's password for Sonic (str)
export const SONIC_AUDIT_LOG_PASSWORD = 'axis@123'

/////CONSOLE FILE EDITOR SETTINGS
export const FILE_EDITOR_CHUNK_SIZE = 25600000; //In Bytes, 25.6MB
export const FILE_EDITOR_MAX_TOTAL_FILE_SIZE = 500000000; //In Bytes, 500MB total amount of file size that can be opened at one time in file editor
export const FILE_EDITOR_SINGLE_FILE_SIZE = 300000000; //In Bytes, 300MB single largest file thats allowed to be open at a time
export const FILE_EDITOR_TEMP_SAVE = true; // Flag to enable/disable temporary save in File Editor
export const FILE_EDITOR_TEMP_SAVE_UPPER_LIMIT = 300000; //In milliseconds, 5 minutes
export const FILE_EDITOR_TEMP_SAVE_LOWER_LIMIT = 10000; //In milliseconds, 10 seconds
export const FILE_EDITOR_TEMP_DIFF = true; //10 seconds
export const FILE_EDITOR_TEMP_DIFF_INTERVAL = 10000; //In milliseconds, 10 seconds
export const FILE_EDITOR_TEMP_SAVE_ON_TAB = true; //Disables/enables temporary file saving on tab switches

/*** ORGANIZATIONS ***/
export const FILE_MANAGER_HOME_PATH = ["/home/", "/home/", "/home"]; //This will determine where the path to user's home folder (eg. '/home/' or '/home_local/' or '/opt/home/')
export const FILE_MANAGER_HOME_RELATIVE_PATH = ["/SecureGenDrive", "/SecureGenDrive", "/SecureGenDrive"]; //Represents a relative path under user's home folder ("" -> '/home/<user>' [This will depend on FILE_MANAGER_HOME_PATH]) - MUST HAVE A LEADING '/'
export const GRID_MONITOR_JOBS_INTERVAL = [10000, 10000, 10000]; //10 seconds
export const GRID_MONITOR_JOBS_INTERVAL_REFRESH = [true, true, true]; //Enable/disable Grid monitor refresh for finished Jobs
export const GRID_MONITOR_PENDING_INTERVAL = [10000, 10000, 10000] // 10 SECOND
export const GRID_MONITOR_PENDING_COLUMN_HIDE = [['slotsTot', 'slotsUsed', 'loadAvg', 'slotsResv', 'slotsID'], ['slotsTot', 'slotsUsed', 'loadAvg', 'slotsResv', 'slotsID'], ['slotsTot', 'slotsUsed', 'loadAvg', 'slotsResv', 'slotsID']] // Columns to hide
export const GRID_MONITOR_PENDING_INTERVAL_REFRESH = [true, true, true] //Enable/disable Grid monitor refresh for pending Jobs
export const PROCESS_MONITOR_JOBS_INTERVAL_REFRESH = [true, true, true]; // Enable/disable process monitor refresh
export const PROCESS_MONITOR_JOBS_INTERVAL = [10000, 10000, 10000]; //10 seconds
export const PROCESS_MONITOR_ALL_INTERVAL_REFRESH = [true, true, true]; // Enable/disable process monitor refresh
export const PROCESS_MONITOR_ALL_INTERVAL = [60000, 60000, 60000]; //60 seconds
export const PROCESS_MONITOR_COLUMN_HIDE = [['cmd'], [], []] // Columns to hide
export const SERVER_MONITOR_INTERVAL = [120000, 12000, 12000]; //In Milliseconds (2 minutes), How often Server Monitor refreshes it's charts
export const SERVER_MONITOR_CPU_TIMEDELTA = [1, 1, 1]; //In Hours, How far back in hours ServerMonitor will grab CPU utilization
export const ANALYSIS_SCHEDULE_INTERVAL_REFRESH = [true, true, true]; // Enable/disable analysis scheduler refresh
export const ANALYSIS_SCHEDULE_INTERVAL = [10000, 10000, 10000]; //10 seconds
export const ANALYSIS_SCHEDULE_OUTPUT = [5000, 5000, 5000]; //5 seconds
export const ANALYSIS_SCHEDULE_POPUP_PATH = ["/home/", "/home/", "/home/"];
export const ANALYSIS_SCHEDULE_POPUP_RELATIVE_PATH = ["/SecureGenDrive", "/SecureGenDrive", "/SecureGenDrive"]; //Represents a reltaive path under user's folder. How it will be used: Combines popup path with username and this path. Example: popup path = /home/, relative = /SecureGenDrive --> final path: /home/{user}/SecureGenDrive
export const ANALYSIS_DATE_FORMAT = ['en-CA','en-CA', 'en-CA'] // Date format during editing https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_tolocalestring_date_all
export const ANALYSIS_SCHEDULE_DAYS = [7, 7, 7] //Amount of days prior to current day that Process Scheduler will show data for. This is only used to display a message in the frontend.
export const ANALYSIS_SCHEDULE_COLUMN_HIDE = [['date_ran', 'date_finished', 'cmd', 'cmd_order', 'dte_submit_display'], [], []] // Columns to hide
export const PROCESS_SCHEDULER_REMOVED_SHOW = [true, false, false] // Default checkbox value to show Removed tasks
export const PROCESS_SCHEDULER_SHOW_ALL_USERS = [true, false, false] // Default checkbox value to show All USers
